.logo-img {
  height: 50px;
  width: 50px;
}

.navbar-container {
  height: 60px;
  background-color: #000231;
}

.header-link {
  color: whitesmoke;
}
.active-header {
  color: white;
  background-color: #797964;
  border-radius: 50%;
}
.header-button {
  padding: 0.5em 1em;
  background-color: inherit;
  border-radius: 10em;
}

.header-link:hover {
  text-decoration: none;
  color: white;
}

.main-content {
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .logo-img {
    height: 40px;
    width: 40px;
    margin-left: 30%;
  }
  .navbar-toggler-icon {
    background-color: #e9a431 !important;
  }
  .navbar {
    padding: 0.5rem 0rem !important;
  }
  .active-header {
    color: white;
    background-color: inherit;
    border-radius: 0%;
  }
  .header-button {
    display: flex;
    justify-content: center;
  }
  .go-btn {
    margin: 3% auto;
  }
  .navbar-collapse {
    background-color: rgb(0, 2, 49);
  }
  .sign-out-btn {
    display: flex !important;
    margin: auto;
    margin-bottom: 10px;
  }
}
