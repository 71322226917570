.available_indicator {
  color: green;
}
.not_available_indicator {
  color: red;
}

.view-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5%;
}

.registered-names-block {
  width: 15%;
  position: fixed;
  top: 57%;
  margin-left: 1%;
  margin-right: 5%;
}
.registered-names-header {
  font-size: 17px;
  font-weight: bolder;
  margin-bottom: 5px;
}
.owner-schedule-selector {
  margin-left: auto;
  margin-right: 2%;
  padding-top: 2%;
  width: 90%;
}

.user-name {
  white-space: wrap;
  width: 100%;
  padding: 2px;
}

.helper-text {
  padding-top: 3%;
}

.timezone-selector-admin {
  width: 13%;
  margin-left: 1%;
  display: flex;
  align-items: center;
  margin-top: -2%;
}

.time-string {
  display: flex;
  margin-left: 1%;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .owner-schedule-selector {
    margin: auto;
    overflow-x: scroll;
    padding-top: 2%;
    width: auto;
  }
  .registered-names-block {
    width: auto;
    margin-left: 1%;
    position: static;
  }
  .timezone-selector-admin {
    width: 50%;
    margin: auto;
  }
}
