.board-title {
  margin-right: auto;
  padding-left: 10px;
}
.task-card {
  user-select: none;
  padding: 16px;
  margin: 0px 0px 8px;
  min-height: 100px;
  background-color: white;
  color: black;
  box-shadow: 0px 0px 7px 1px grey;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.task-card:hover .task-title-1 {
  word-break: break-word;
  -webkit-line-clamp: initial;
  overflow: auto;
}
.task-title-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.task-details {
  display: flex;
  justify-content: space-between;
}

.task-details-field {
  display: flex;
  align-items: self-end;
}
