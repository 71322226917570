.create-meeting-form {
  width: 75%;
  margin: auto;
  padding-top: 3%;
  padding-bottom: 5%;
}

.registered-names-block {
  width: 15%;
  position: fixed;
  top: 45%;
  margin-left: 1%;
  margin-right: 5%;
}

.meeting-form-header {
  font-size: 17px;
  font-weight: bolder;
  text-align: center;
  margin-top: 3%;
}

.confirm-details-view {
  width: 75%;
  margin: auto;
  padding-bottom: 5%;
}
.field-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}
.field-header {
  font-size: 17px;
  font-weight: bold;
}
.field-value {
  width: 50%;
  word-break: break-all;
}

.meeting-time-header {
  text-align: center;
  margin-top: 1%;
}

.updated-time {
  margin-top: -44px;
  margin-left: 1%;
  margin-bottom: 35px;
}
.helper-text-team {
  margin-top: -40px;
  text-align: center;
  font-size: 17px;
  font-weight: bolder;
}
.timezone-selector-team {
  width: 6%;
  margin-left: 1%;
  display: flex;
  align-items: center;
  margin-top: -2%;
}

.selection-scheme-team {
  width: 14%;
}
.team-select-group {
  display: flex;
  justify-content: space-evenly;
}
.w-30 {
  width: 30% !important;
}
.notes-icon {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 50px;
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  background-color: #000231;
  border-radius: 50%;
  cursor: pointer;
}
.team-schedule-selector {
  margin: auto;
  padding-top: 2%;
  width: 90%;
}
.cursor-pointer {
  cursor: pointer;
}
.time-block {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  padding: 6px;
}
.available-people-indicator {
  width: 15%;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10%;
}
.selected-indicator {
  border: 3px solid #e74471;
  padding: 3px;
}
.todo {
  align-items: center;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

@media screen and (max-width: 768px) {
  .timezone-selector-team {
    width: fit-content;
  }
  .helper-text-team {
    margin-top: auto;
  }
  .team-select-group {
    flex-direction: column;
    width: 50%;
    margin: auto;
  }
  .available-people-indicator {
    width: auto;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10%;
  }
  .selection-scheme-team {
    width: auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 1150px) {
  .week-selector {
    width: 20%;
  }
}
