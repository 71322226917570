.signin-form {
  width: 20%;
  margin: auto;
  padding-top: 5%;
}

.schedule-selector {
  width: 80%;
  margin: auto;
  padding-top: 2%;
}

.event-details-container {
  margin-top: 15px;
  margin-left: 15px;
}

.event-data {
  font-size: 17px;
}

.event-header {
  font-size: 16px;
  font-weight: bolder;
}

.helper-text {
  text-align: center;
  font-size: 17px;
  font-weight: bolder;
}

.selection-scheme {
  width: 25%;
}

.cursor-pointer {
  cursor: pointer;
}

.event-data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .event-data {
    text-align: center;
  }
  .custom-switch {
    z-index: -1 !important;
    position: relative;
  }
  .selection-scheme {
    width: 75%;
  }
  .event-data-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 1221px) {
  .signin-form {
    width: 80%;
  }
}
