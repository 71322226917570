.meetings-view {
  width: 50%;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 5%;
}

.custom-card {
  box-shadow: 0 15px 25px 0 rgb(0 0 0 / 20%);
  margin-bottom: 3%;
  cursor: pointer;
}

.card-field {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
  justify-content: space-between;
}

.card-field-header {
  font-weight: bolder;
  width: 35%;
}

.card-field-value {
  font-size: 16px;
  word-break: break-word;
  width: 60%;
}

.week-dropdown-empty {
  width: 13%;
  margin: auto;
  margin-top: 2%;
}

.select-dropdown {
  width: 17%;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
}

.dropdown-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.highlight-attendee {
  background-color: lightgreen !important;
}

@media screen and (max-width: 768px) {
  .select-dropdown {
    width: 60%;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .select-dropdown {
    width: 25%;
  }
}
