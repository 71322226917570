.timezone-selector-new {
  width: 7%;
  margin-bottom: 2%;
  width: 13%;
  margin-left: 1%;
  display: flex;
  align-items: center;
  margin-top: -2%;
}
.rbc-today {
  font-weight: bold;
  background-color: lightgreen !important;
}

.event-details {
  font-size: 12px;
}

.calendar-view {
  height: 600px;
}
@media screen and (max-width: 768px) {
  .timezone-selector-new {
    width: fit-content;
    margin-bottom: 2%;
  }
}

.critical-indi {
  background-color: darkred !important;
  color: white;
}

.warning-indi {
  background-color: #acac0c !important;
  color: white;
}

.task-title {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DraftEditor-editorContainer {
  height: 300px !important;
}
