.tabs-view {
  width: 95%;
  margin: auto;
  margin-top: 3%;
}

.dropdown-container-weekly {
  width: 60%;
  margin: auto;
  display: flex;
}
a.nav-item {
  color: black;
}

.helper-text-weekly {
  text-align: center;
  font-size: 17px;
  font-weight: bolder;
}
.content-tabs {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin: 0;
  padding: 0;
  justify-content: space-around;
  background-color: lightblue;
}

@media screen and (max-width: 992px) {
  .dropdown-container-weekly {
    width: auto;
    flex-direction: column;
  }
}
@media screen and (min-width: 993px) and (max-width: 1250px) {
  .dropdown-container-weekly {
    width: 80%;
  }
}
